import React, { useEffect, useState, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { signUpUser } from "../Home/ducks/services";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import FormGroup from "Molecules/FormGroup";

import topbarLogo from "Assets/images/medical-alert-logo.png";
import closeIcon from "Assets/images/close-icon.svg";
import { Modal } from "react-bootstrap";
import PricingPackageMembership from "../../components/PricingPackageMembership";

import { useGlobalState } from "../../context/GlobalStateProvider";

// Constant Data
import { csPrefillTxt } from "../../configs/constantData";

const countries = ["Malaysia", "Singapore"];

const states = [
  "WP Kuala Lumpur",
  "Johor",
  "Kedah",
  "Kelantan",
  "Melaka",
  "Negeri Sembilan",
  "Pahang",
  "Penang",
  "Perak",
  "Perlis",
  "Sabah",
  "Sarawak",
  "Selangor",
  "Terengganu",
  "WP Labuan",
  "WP Putrajaya",
  "Singapore",
];

const formFields = [
  {
    name: "email",
    label: "Email",
    type: "email",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Email is Required",
    size: "lg",
  },
  {
    name: "name",
    label: "Full Name",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Full Name is Required",
    size: "lg",
  },
  {
    name: "nric",
    label: "IC Number / Passport",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "IC Number / Passport is Required",
    size: "lg",
  },
  {
    name: "gender",
    label: "Gender",
    type: "select",
    options: ["Male", "Female"],
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Gender is Required",
    size: "lg",
  },
  {
    name: "race",
    label: "Ethnic",
    type: "input",
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Ethnic is Required",
    size: "lg",
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Date of Birth is Required",
    size: "lg",
  },
  {
    name: "phone",
    label: "Phone (Mobile)",
    type: "input",
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Phone (Mobile) is Required",
    size: "lg",
  },
  {
    name: "home_phone",
    label: "Phone (Home)",
    type: "input",
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Phone (Home) is Required",
    size: "lg",
  },
  {
    name: "address",
    label: "Mailing Address",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Mailing Address is Required",
    size: "lg",
  },
  {
    name: "state",
    label: "State",
    type: "select",
    options: states,
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "State is Required",
    size: "lg",
  },
  {
    name: "postcode",
    label: "Postcode",
    type: "input",
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Postcode is Required",
    size: "lg",
  },
  {
    name: "country",
    label: "Country",
    type: "select",
    options: countries,
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Country is Required",
    size: "lg",
  },
  {
    name: "nationality",
    label: "Nationality",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Nationality is Required",
    size: "lg",
  },
  {
    name: "referral_code",
    label: "Referral Code",
    type: "input",
    req: false,
    class: "col-md-6 col-12",
    size: "lg",
  },
];

const pricingPackageList = [
  {
    price: "RM250",
    packageType: "new",
    package: "NEW_1Y",
    tips: "(For New Members)",
    foreignerPrice: "[RM500 for non-Malaysian]",
    desc: `1-year MedicAlert Foundation Membership for 1 person.
    A pendant and wristband that stores your medical records, accessible 24/7 worldwide. You can also update your details online, anytime`,
    year: 1,
  },
  {
    price: "RM450",
    packageType: "new",
    package: "NEW_3Y",
    tips: "(For New Members)",
    foreignerPrice: "[RM900 for non-Malaysian]",
    desc: `3-year MedicAlert Foundation Membership for 1 person.
    A pendant and wristband that stores your medical records, accessible 24/7 worldwide. You can also update your details online, anytime`,
    year: 3,
  },
  {
    price: "RM200",
    packageType: "renew",
    package: "RENEWAL_1Y",
    tips: "(For Renewing Members)",
    foreignerPrice: "[RM400 for non-Malaysian]",
    desc: `1-year MedicAlert Foundation Membership for 1 person.
    A pendant and wristband that stores your medical records, accessible 24/7 worldwide. You can also update your details online, anytime`,
    year: 1,
  },
];

export default (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const queryParameters = new URLSearchParams(location.search);
  const referral = location.state?.referral || queryParameters.get("aff_id");

  const { isMobile } = useGlobalState();

  const [openRenewModal, setOpenRenewModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedPackageType, setSelectedPackageType] = useState("");
  const selectedPackageDetail = pricingPackageList?.find(
    (item) => selectedPackage === item.package
  );

  const handleCloseRenewModal = () => {
    setOpenRenewModal(false);
    setSelectedPackage("");
    setSelectedPackageType("");
  };

  const handleSelectPackage = (type, packageName) => {
    setSelectedPackage(packageName);
    setSelectedPackageType(type);
    if (type === "renew") setOpenRenewModal(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (!isMobile) navigate("/membership");
  }, [isMobile]);

  useEffect(() => {
    if (referral) {
      setValue("referral_code", referral);
    }
  }, [referral]);

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      dob: dayjs(val?.dob).format("YYYY-MM-DD"),
      product_code: selectedPackage,
    };

    await signUpUser(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          window.location.href = response.data.data.url;
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="signup-container">
        <div className="signup-header">
          <img
            className="company-logo"
            src={topbarLogo}
            alt="Medical Alert Logo"
          />
          <img
            className="close-btn"
            src={closeIcon}
            alt="Close Icon"
            onClick={goBack}
          />
        </div>
        <div className="signup-body">
          <div className="container">
            <div className="row">
              <div
                className="sign-up-body-title text-center"
                style={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                  marginBottom: "2rem",
                }}
              >
                <div>Join Now</div>
                <div className="primary-color">Membership</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-1 mb-5">
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem",
                    border: "2px solid #ED174E",
                    borderRadius: "15px",
                    color: "#ED174E",
                    fontWeight: "bold",
                    background: "#fff",
                    fontSize: "1.5rem",
                    textAlign: "center",
                  }}
                >
                  Your one-year membership package includes an NFC bracelet or
                  pendant. Simply scan them with a smartphone to access your
                  medical information.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-body">
                <Form noValidate>
                  <div>
                    <div className="card-body">
                      <div className="row">
                        {formFields?.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <FormGroup
                                item={item}
                                errors={errors}
                                register={register}
                              />
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Form.Check
                  type="checkbox"
                  id={`default-checkbox`}
                  className="fz-10"
                  onChange={(e) => setIsChecked(e.target.checked)}
                  label={`
                    By accepting membership in The MedicAlert® foundation, I authorize MedicAlert to release all medical and other confidential information about me, in emergencies, to other healthcare personnel. MedicAlert relies upon the accuracy of the information members supply.
                    Member therefore agrees to defend, indemnify and hold MedicAlert (including its employee, officers and agents) harmless from any claim or lawsuit brought by member or others for injury, death, loss or damages arising in whole or in part out of member's provision of incomplete or inaccurate information to MedicAlert.
                  `}
                />
              </div>
            </div>

            <PricingPackageMembership
              handleSelectPackage={handleSelectPackage}
              pricingPackageList={pricingPackageList}
            />
          </div>
        </div>
        <div
          className={`signup-footer ${
            selectedPackageType === "new" ? "active" : ""
          }`}
        >
          <div className="signup-footer-container">
            <div className="signup-footer-title">
              <div className="signup-footer-title-one">
                Total Cost: New Membership {selectedPackageDetail?.year} year
                {selectedPackageDetail?.year > 1 ? "s" : ""}
              </div>
              <div className="signup-footer-title-two">
                ={selectedPackageDetail?.price}.00
              </div>
            </div>
            <Button
              className="signup-footer-btn primary-btn w-100"
              disabled={!isChecked}
              onClick={handleSubmit(onFinish)}
            >
              Make Payment
            </Button>
          </div>
        </div>
      </div>

      <Modal
        show={openRenewModal}
        onHide={handleCloseRenewModal}
        centered
        size="lg"
      >
        <Modal.Header closeButton style={{ padding: "20px" }}></Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <div>
            If you are an existing member and a first-time login, click{" "}
            <a
              href={csPrefillTxt}
              target="_blank"
              className="text-primary font-bold"
            >
              here
            </a>{" "}
            to speak to our Customer Service and we shall assist you in
            retrieving your first-time login ID and Password.
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
