import * as action_types from './constants';

const initialState = {
  user: {},
  userOption: {}
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.USER_DETAIL:
      return { ...state, user: data, userOption: meta };
    
    default:
      return state;
  }
};
