import axios from "Services/axiosInterceptor";
import * as action_types from "./constants";
import { externalBaseUrl } from "../../../../configs/constants";

export const getUser = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    try {
      const {
        data: { data, meta },
      } = await axios.post(`${externalBaseUrl}/users/get`, {
        user_id: id,
      });
      load && load(false);
      dispatch({
        type: action_types.USER_DETAIL,
        data: data,
        meta: meta,
      });
    } catch (error) {
      console.log(error);
    } finally {
      load && load(false);
    }
  };
};
