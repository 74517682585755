import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

// Constant Data
import { csPrefillTxt } from "../../../configs/constantData";

// Components
import PricingPackageMembership from "../../../components/PricingPackageMembership";

// Packages
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import FormGroup from "Molecules/FormGroup";
// import DatePicker from "react-widgets/DatePicker";
import { Modal } from "react-bootstrap";

// Ducks
import { signUpUser } from "../ducks/services";

// Images
import HeroImg from "Assets/images/membership/hero.jpg";
import HeroImgMobile from "Assets/images/membership/hero.jpg";
import ExperienceIcon from "Assets/images/membership/experience.png";
import NdisIcon from "Assets/images/membership/ndis.png";
import SecureOnlineIcon from "Assets/images/membership/secure-online.png";
import SupportIcon from "Assets/images/membership/support.png";
import TwiceVerifiedIcon from "Assets/images/membership/twice-verified.png";
import UnlimitedIcon from "Assets/images/membership/unlimited.png";
import YourIdIcon from "Assets/images/membership/your-id.png";
import Button from "react-bootstrap/Button";
import stepImg from "Assets/images/step-img.svg";
import Banner from "../../../components/common/Banner";

import { useGlobalState } from "../../../context/GlobalStateProvider";

const countries = ["Malaysia", "Singapore"];

const states = [
  "WP Kuala Lumpur",
  "Johor",
  "Kedah",
  "Kelantan",
  "Melaka",
  "Negeri Sembilan",
  "Pahang",
  "Penang",
  "Perak",
  "Perlis",
  "Sabah",
  "Sarawak",
  "Selangor",
  "Terengganu",
  "WP Labuan",
  "WP Putrajaya",
  "Singapore",
];

const cardSteps = [
  {
    icon: UnlimitedIcon,
    title: "Unlimited Access To Your Data",
    content:
      "You can access your data as many times as you like by scanning your NFC-activated medical tags to check your information and share it with doctors, nurses, and hospital administrative staff.",
    toggled: true,
    index: 0,
  },
  {
    icon: SecureOnlineIcon,
    title: "Secure Online Storage",
    content:
      "We securely store your additional medical history, care plans, reports and an Advance Care Directive, ensuring all of your health information can be accessed quickly by healthcare professionals.",
    toggled: false,
    index: 1,
  },
  {
    icon: TwiceVerifiedIcon,
    title: "Twice Verified Records",
    content:
      "Every entry into your electronic health record is checked twice – once against a global-standard list of medications, conditions and allergies, and again by our in-house team.",
    toggled: false,
    index: 2,
  },
  {
    icon: YourIdIcon,
    title: "Customised For You",
    content:
      "Every medical ID, such as your bracelet and pendant, has your vital information stored in the NFC-embedded chip. Your medical ID is not valid unless you are registered in our database.",
    toggled: false,
    index: 3,
  },
  {
    icon: SupportIcon,
    title: "Expert Support",
    content:
      "Expert support is only a phone call away. Our local based team has a wealth of knowledge to ensure that your MedicAlert ID and membership is correct, up to date and ready to protect you in an emergency.",
    toggled: false,
    index: 4,
  },
  {
    icon: ExperienceIcon,
    title: "50+ Years Of Experience",
    content:
      "For over 50 years, the MedicAlert Foundation has given patient with hidden health conditions peace of mind that we’ll communicate for them in everyday and emergency situations.",
    toggled: false,
    index: 5,
  },
  // {
  //   icon: NdisIcon,
  //   title: "Insurance claimable",
  //   content:
  //     "Most medical insurers will refund you the money you pay for your medical ID. Please check with your insurance company.",
  //   toggled: false,
  //   index: 6,
  // },
];

const formFields = [
  {
    name: "email",
    label: "Email",
    type: "email",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Email is Required",
    size: "lg",
  },
  {
    name: "name",
    label: "Full Name",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Full Name is Required",
    size: "lg",
  },
  {
    name: "nric",
    label: "IC Number / Passport",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "IC Number / Passport is Required",
    size: "lg",
  },
  {
    name: "gender",
    label: "Gender",
    type: "select",
    placeholder: "Please select",
    options: ["Male", "Female"],
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Gender is Required",
    size: "lg",
  },
  {
    name: "race",
    label: "Ethnic",
    type: "input",
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Ethnic is Required",
    size: "lg",
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Date of Birth is Required",
    size: "lg",
  },
  {
    name: "phone",
    label: "Phone (Mobile)",
    type: "input",
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Phone (Mobile) is Required",
    size: "lg",
  },
  {
    name: "home_phone",
    label: "Phone (Home)",
    type: "input",
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Phone (Home) is Required",
    size: "lg",
  },
  {
    name: "address",
    label: "Mailing Address",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Mailing Address is Required",
    size: "lg",
  },
  {
    name: "state",
    label: "State",
    type: "select",
    options: states,
    placeholder: "Please select",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "State is Required",
    size: "lg",
  },
  {
    name: "postcode",
    label: "Postcode",
    type: "input",
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Postcode is Required",
    size: "lg",
  },
  {
    name: "country",
    label: "Country",
    type: "select",
    placeholder: "Please select",
    options: countries,
    req: true,
    class: "col-md-3 col-12",
    reqmsg: "Country is Required",
    size: "lg",
    onChange: (e) => {
      if (e.target.value !== "Malaysia") {
        // setPrice("500"); set double price
      } else {
        // setPrice("250"); original price
      }
    },
  },
  {
    name: "nationality",
    label: "Nationality",
    type: "input",
    req: true,
    class: "col-md-6 col-12",
    reqmsg: "Nationality is Required",
    size: "lg",
  },
  {
    name: "referral_code",
    label: "Referral Code",
    type: "input",
    req: false,
    class: "col-md-6 col-12",
    size: "lg",
  },
];

const pricingPackageList = [
  {
    price: "RM250",
    packageType: "new",
    package: "NEW_1Y",
    tips: "(For New Members)",
    foreignerPrice: "[RM500 for non-Malaysian]",
    desc: `1-year MedicAlert Foundation Membership for 1 person.
    A pendant and wristband that stores your medical records, accessible 24/7 worldwide. You can also update your details online, anytime`,
  },
  {
    price: "RM450",
    packageType: "new",
    package: "NEW_3Y",
    tips: "(For New Members)",
    foreignerPrice: "[RM900 for non-Malaysian]",
    desc: `3-year MedicAlert Foundation Membership for 1 person.
    A pendant and wristband that stores your medical records, accessible 24/7 worldwide. You can also update your details online, anytime`,
  },
  {
    price: "RM200",
    packageType: "renew",
    package: "RENEWAL_1Y",
    tips: "(For Renewing Members)",
    foreignerPrice: "[RM400 for non-Malaysian]",
    desc: `1-year MedicAlert Foundation Membership for 1 person.
    A pendant and wristband that stores your medical records, accessible 24/7 worldwide. You can also update your details online, anytime`,
  },
];

export default (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [showPackageDetail, setShowPackageDetail] = useState(false);
  const queryParameters = new URLSearchParams(location.search);
  const referral = queryParameters.get("aff_id");
  const { isMobile } = useGlobalState();
  const [openRenewModal, setOpenRenewModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedPackageType, setSelectedPackageType] = useState("");

  const handleSelectPackage = (type, selectedPackage) => {
    setSelectedPackage(selectedPackage);
    setSelectedPackageType(type);
    if (type === "new") {
      document.getElementById("new-member-form").scrollIntoView({
        behavior: "smooth",
      });
    }
    if (type === "renew") setOpenRenewModal(true);
  };

  const handleCloseRenewModal = () => {
    setOpenRenewModal(false);
    setSelectedPackage("");
    setSelectedPackageType("");
  };

  useEffect(() => {
    if (isMobile && referral) {
      navigate("/sign-up", { state: { referral: referral } });
    }
  }, [isMobile]);

  // Mobile Package Detail UI
  const toggleShowPackageDetail = () => {
    setShowPackageDetail(!showPackageDetail);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (referral) {
      setValue("referral_code", referral);
    }
  }, [referral]);

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      dob: dayjs(val?.dob).format("YYYY-MM-DD"),
      product_code: selectedPackage,
    };

    await signUpUser(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code === 200) {
          window.location.href = response.data.data.url;
          let data = response.data.data.user;
          let res = {
            access_token: data.access_token, // byebug access token
            expires_in: data.expires_in,
            refresh_token: data.refresh_token,
          };
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem("userid", JSON.stringify(data.id));
          if (data.username) localStorage.setItem("userName", data.username);
          if (data.email) localStorage.setItem("email", data.email);
          if (data.photo_url) localStorage.setItem("userImage", data.photo_url);
          localStorage.setItem("token", JSON.stringify(res));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        props.setLoading(false);
        toast.error(e.message ?? "Something went wrong");
      });
  };

  const navigateToSignUp = () => {
    navigate("/sign-up");
  };

  return (
    <>
      <div className="membership">
        {/* Header */}
        <section className="top-title">
          <div className="primary-color">Features and Benefits</div>
        </section>

        {/* Banner */}
        <Banner webBanner={HeroImg} mobileBanner={HeroImgMobile} />

        {/* Cards */}
        <section className="section-features">
          <div className="features-container">
            <div className="features-boxes">
              {cardSteps.map((item) => {
                return (
                  <FeatureBox
                    item={item}
                    key={item.title}
                    isMobile={isMobile}
                  />
                );
              })}
            </div>
          </div>
        </section>

        {/* Pricing */}
        <section className="section-bottom" style={{ marginTop: "4em" }}>
          <div className="container">
            <div className="bottom-container">
              <div className="bottom-header text-start">
                <div className="text-primary mb-3 text-center">
                  Donate and Unlock Amazing Benefits!
                </div>
                {!isMobile && (
                  <PricingPackageMembership
                    handleSelectPackage={handleSelectPackage}
                    pricingPackageList={pricingPackageList}
                  />
                )}
              </div>
              <div className="bottom-body d-none d-md-block">
                <p className="mb-5 step-box-body-content">
                  With MedicAlert, you can rest assured knowing that your
                  critical health information is readily available at any time,
                  no matter where you are.
                </p>
                <p className="mb-5 step-box-body-content">
                  Your annual membership directly contributes to the MedicAlert
                  Foundation's mission of providing this invaluable service to
                  countless Malaysians coping with hidden or chronic health
                  conditions.
                </p>
                <p className="mb-0 step-box-body-content">
                  Join us in supporting this essential cause and enjoy the peace
                  of mind that comes with knowing your health information is
                  always at your fingertips.
                </p>

                <div className="form-body">
                  <Form onSubmit={handleSubmit(onFinish)} id="new-member-form">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 text-center bottom-header mb-0">
                            <div className="text-dark mb-0">
                              Donate & Sign Up Now
                            </div>
                            {/* <div className="text-primary">Membership</div> */}
                          </div>

                          <div className="col-12 text-center mb-5">
                            <p className="step-box-body-content">
                              Are you an existing MedicAlert member? Please{" "}
                              <Link
                                to="/login"
                                className="font-bold primary-color"
                              >
                                Log In
                              </Link>
                            </p>
                          </div>

                          {/* <div className="col-12 text-center mb-5">
                            <p className="step-box-body-content">
                              Please note, this may take up to 48 hours to
                              process.
                            </p>
                          </div> */}
                          {formFields?.map((item, index) => {
                            return (
                              <Fragment key={index}>
                                <FormGroup
                                  item={item}
                                  errors={errors}
                                  register={register}
                                />
                              </Fragment>
                            );
                          })}
                        </div>

                        <div className="row">
                          <div className="col-3 text-center mt-1 mb-5">
                            <img src={stepImg} alt="" className="w-100" />
                          </div>
                          <div className="col-9 mt-1 mb-5">
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "30px",
                                border: "2px solid #ed174e",
                                borderRadius: "15px",
                                color: "#ed174e",
                                fontWeight: "bold",
                              }}
                            >
                              Your one-year membership package includes an NFC
                              bracelet or pendant. Simply scan them with a
                              smartphone to access your medical information.
                            </div>
                          </div>
                        </div>

                        {selectedPackageType === "new" && (
                          <>
                            <div className="row">
                              <div className="col-12">
                                <Form.Check
                                  type="checkbox"
                                  id={`membership-checkbox`}
                                  className="fz-10"
                                  onChange={(e) =>
                                    setIsChecked(e.target.checked)
                                  }
                                  label={`
                                        By accepting membership in The MedicAlert® foundation, I authorize MedicAlert to release all medical and other confidential information about me, in emergencies, to other healthcare personnel. MedicAlert relies upon the accuracy of the information members supply.
                                        Member therefore agrees to defend, indemnify and hold MedicAlert (including its employee, officers and agents) harmless from any claim or lawsuit brought by member or others for injury, death, loss or damages arising in whole or in part out of member's provision of incomplete or inaccurate information to MedicAlert.
                                      `}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <hr />
                              </div>
                            </div>

                            <div className="row my-5 justify-content-end">
                              {/* <div className="col-6">
                                    <h3
                                      className="text-primary text-end"
                                      style={{ lineHeight: "150%" }}
                                    >
                                      Total Cost: New Membership 1 year
                                      <br />
                                      =RM250.00
                                    </h3>
                                  </div>
                                  <div className="col-1"></div> */}
                              <div className="col-4">
                                <Button
                                  className="w-100 text-white"
                                  style={{
                                    borderRadius: "15px",
                                    fontSize: "100%",
                                    height: "50px",
                                  }}
                                  type="submit"
                                  disabled={!isChecked}
                                >
                                  Make Payment
                                </Button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="row d-md-none bottom-mobile-body">
                <div className="col-12 mb-5">
                  <div className="package-details">
                    <div
                      className={`package-details-select font-bold flex ju-c-sb al-i-c ${
                        showPackageDetail ? "v-hidden" : ""
                      }`}
                      onClick={toggleShowPackageDetail}
                    >
                      Package Details
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.599"
                        height="9"
                        viewBox="0 0 16.599 9"
                        className="closed"
                      >
                        <path
                          id="Polygon_31"
                          data-name="Polygon 31"
                          d="M8.3,0l8.3,9H0Z"
                          transform="translate(16.599 9) rotate(180)"
                          fill="#061528"
                        />
                      </svg>
                    </div>
                    <div
                      className={`package-details-dropdown ${
                        showPackageDetail ? "" : "display-none"
                      }`}
                    >
                      <div
                        className="package-details-dropdown-header font-bold flex ju-c-sb al-i-c"
                        onClick={toggleShowPackageDetail}
                      >
                        Package Details
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.599"
                          height="9"
                          viewBox="0 0 16.599 9"
                          className="arrow"
                        >
                          <path
                            id="Polygon_31"
                            data-name="Polygon 31"
                            d="M8.3,0l8.3,9H0Z"
                            transform="translate(16.599 9) rotate(180)"
                            fill="#061528"
                          />
                        </svg>
                      </div>
                      <div className="package-details-dropdown-body">
                        <div className="package-details-dropdown-body-title font-bold primary-color mb-5">
                          <div>MedicAlert membership,</div>
                          <div>with Pendant and Bracelet</div>
                        </div>
                        <div className="package-details-dropdown-body-content secondary-color">
                          <div className="mb-3">
                            The MedicAlert service gives you peace of mind that
                            your vital health information is accessible no
                            matter where you are, 24 hours a day.
                          </div>
                          <div className="mb-3">
                            MedicAlert has a joining fee of RM250, to set up
                            your membership profile and medical record. The
                            MedicAlert Foundation service includes QR Code or
                            24/7 Emergency Information hotline access to your
                            MedicAlert record, unlimited wallet & fridge cards
                            and exceptional support to ensure that your vital
                            health information is always accessible, correct and
                            up-to-date.
                          </div>
                          <div className="mb-3">
                            Your annual service fee supports the MedicAlert
                            Foundation to offer this valuable service to
                            thousands of Malaysians living with a hidden or
                            chronic health condition.
                          </div>
                          <div className="mb-3">
                            With a wide range of Medical IDs, we have options to
                            suit every lifestyle and budget.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <Button
                    variant="primary"
                    className="w-100 text-white"
                    style={{
                      borderRadius: "30px",
                      fontSize: "100%",
                      height: "60px",
                    }}
                    onClick={navigateToSignUp}
                  >
                    Apply Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        show={openRenewModal}
        onHide={handleCloseRenewModal}
        centered
        size="lg"
      >
        <Modal.Header closeButton style={{ padding: "20px" }}></Modal.Header>
        <Modal.Body style={{ padding: "30px" }}>
          <div>
            If you are an existing member and a first-time login, click{" "}
            <a
              href={csPrefillTxt}
              target="_blank"
              className="text-primary font-bold"
            >
              here
            </a>{" "}
            to speak to our Customer Service and we shall assist you in
            retrieving your first-time login ID and Password.
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

function FeatureBox({ item, isMobile }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isMobile) setIsOpen(item.index === 0 ? true : false);
    else setIsOpen(false);
  }, [isMobile]);

  const handleOpen = () => {
    if (!isMobile) return;
    setIsOpen((isOpen) => !isOpen);
  };

  const contentStyle = {
    maxHeight: isOpen ? "500px" : "7.2rem",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  };

  return (
    <div
      className={`feature-box ${isOpen ? "open" : ""}`}
      style={isMobile ? contentStyle : {}}
    >
      <div className="m-dev">
        <div
          className={`feature-box-header flex al-i-c ${
            isOpen ? "ju-c-end" : "ju-c-sb"
          }`}
          onClick={handleOpen}
        >
          {!isOpen && (
            <div className="primary-color font-bold">{item.title}</div>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.599"
            height="9"
            viewBox="0 0 16.599 9"
          >
            <path
              id="Polygon_31"
              data-name="Polygon 31"
              d="M8.3,0l8.3,9H0Z"
              transform="translate(16.599 9) rotate(180)"
              fill="#061528"
            />
          </svg>
        </div>
      </div>
      <div className="feature-box-body">
        <div className="feature-img">
          <img src={item.icon} alt={item.title} />
        </div>
        <div className="feature-desc">
          <div className="feature-desc-title primary-color font-bold mb-3">
            {item.title}
          </div>
          <div className="feature-desc-body secondary-color">
            {item.content}
          </div>
        </div>
      </div>
    </div>
  );
}
