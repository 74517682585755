import React, { useEffect, useState } from "react";
import LoginLayout from "../../templates/LoginLayout";
import Account from "Modules/Dashboard/Account";
import Profile from "Modules/Dashboard/Profile";
import Faq from "Modules/Dashboard/Faq";
import Privacy from "Modules/Dashboard/Privacy";
import UserMedicalInfo from "Modules/Dashboard/UserMedicalInfo";
import { GlobalStateProvider } from "../../context/GlobalStateProvider";

const Components = {
  Account,
  Profile,
  Faq,
  Privacy,
  UserMedicalInfo,
};

export default (props) => {
  const DashboardComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <GlobalStateProvider>
      <LoginLayout loading={loading}>
        <DashboardComp loading={loading} setLoading={setLoading} />
      </LoginLayout>
    </GlobalStateProvider>
  );
};
